import { Box, SlashHeader } from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import arrayHasData from 'lib/arrayHasData'
import ViewMoreButton from '@/components/generic/view-more-button'
import Date from '@/components/generic/date'
import messages from '@/messages/magazine'
import LatestNewsCard from '@/components/editorial/cards/latest-news'
import HorizontalNewsListingCard from '@/components/editorial/cards/horizontal-news-listing'
import testIds from '@/enums/testIds'

const LatestNewsListing = ({ headlineNews, latestNews, formatTypeUrl }) => {
  const intl = useIntl()

  const headline = arrayHasData(headlineNews) ? headlineNews[0] : latestNews[0]

  const [...newsItems] = latestNews
    .filter((x) => x.id !== headline.id)
    .slice(0, 3)

  return (
    <>
      <SlashHeader>{intl.formatMessage(messages.latestNews)}</SlashHeader>
      <Box pb={{ s: 3, m: 4 }} data-testid={testIds.headlineNews}>
        <LatestNewsCard
          {...headline}
          formatTypeUrl={formatTypeUrl}
          dateFormat={Date.Formats.News}
        />
      </Box>
      {newsItems.map((news) => (
        <Box pb={3} key={news.id} data-testid={testIds.latestNews}>
          <HorizontalNewsListingCard
            {...news}
            formatTypeUrl={formatTypeUrl}
            dateFormat={Date.Formats.News}
          />
        </Box>
      ))}
      <ViewMoreButton href="/news">
        {intl.formatMessage(messages.viewMoreNews)}
      </ViewMoreButton>
    </>
  )
}

LatestNewsListing.propTypes = {
  headlineNews: PropTypes.array.isRequired,
  latestNews: PropTypes.array.isRequired,
  formatTypeUrl: PropTypes.func.isRequired,
}

export default LatestNewsListing
