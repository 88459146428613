import { useQuery } from '@apollo/client'
import { SubSection } from '@resident-advisor/design-system'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import QueryResultHandler from '@/components/generic/query-result-handler'
import HorizontalList from '@/components/generic/horizontal-list'
import FeatureCard from '@/components/editorial/cards/feature'
import GET_FEATURES from '@/queries/GetFeaturesQuery'
import messages from '@/messages/magazine'
import ViewMoreButton from '@/components/generic/view-more-button'

const LatestFeatures = ({ title, count, moreButton, excludeIds }) => {
  const queryProps = useQuery(GET_FEATURES, {
    variables: { orderBy: 'LATEST', count, type: 'STANDARD', excludeIds },
  })

  return (
    <QueryResultHandler
      {...queryProps}
      dataKey="features"
      title={title}
      markup={LatestFeaturesMarkup}
      moreButton={moreButton}
    />
  )
}

LatestFeatures.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  moreButton: PropTypes.bool,
  excludeIds: PropTypes.array,
}

LatestFeatures.defaultProps = {
  count: 6,
  moreButton: true,
}

const LatestFeaturesMarkup = ({ data, title, moreButton }) => {
  const intl = useIntl()
  return (
    <SubSection.Stacked title={title}>
      <HorizontalList
        Card={FeatureCard}
        mCols={3}
        lWrap
        Button={
          moreButton && (
            <ViewMoreButton href="/features">
              {intl.formatMessage(messages.viewMoreFeatures)}
            </ViewMoreButton>
          )
        }
        items={data}
      />
    </SubSection.Stacked>
  )
}

LatestFeaturesMarkup.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  moreButton: PropTypes.bool,
}

export { LatestFeaturesMarkup }
export default LatestFeatures
