import { Grid, Column, Alignment } from '@resident-advisor/design-system'
import PropTypes from 'prop-types'
import FullWidth from '@/components/generic/full-width'
import BasicCard from '@/components/generic/basic-card'

const FeaturedItem = ({ Media, Card, Button, item, ...props }) => (
  <Alignment flexDirection="column" {...props}>
    <Grid lCols={3} mCols={1} sCols={1} mWrap sWrap>
      <Column lSpan={2}>
        <FullWidth sizes={['s']}>
          <Media {...item} />
        </FullWidth>
      </Column>
      <Column>
        <Card isFeatured {...item} imageUrl="" />
      </Column>
    </Grid>

    {Button}
  </Alignment>
)

FeaturedItem.propTypes = {
  Media: PropTypes.func.isRequired,
  Card: PropTypes.func,
  Button: PropTypes.object,
  item: PropTypes.object.isRequired,
}

FeaturedItem.defaultProps = {
  Card: BasicCard,
}

export default FeaturedItem
